import { useTheme } from '@mui/material';

export const GetLogoPathByFunction = (): string => {
	const environment = process.env.NEXT_PUBLIC_ENV;
	const theme = useTheme();

	const logoPaths = {
		staging: '/static/icons/brand/logo_green.svg',
		development: '/static/icons/brand/logo_orange.svg',
		light: '/static/icons/brand/logo_dark.svg',
		dark: '/static/icons/brand/logo_white.svg'
	};

	if (environment === 'staging') return logoPaths.staging;
	if (environment === 'development') return logoPaths.development;

	// Donc en Production
	return theme.palette.mode === 'light' ? logoPaths.light : logoPaths.dark;
};
